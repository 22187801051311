import styled from 'styled-components';
import Page from '../components/Page'
import { breakpoints, fromDesktop, fromMedium, toTablet } from '../styles/mediaQueries';
import { PageTitle } from '../components/ui/PageTitle';
import { ButtonLink } from '../components/ui/Button';
import AboutMe from '../components/AboutMe';
import { primary, primarySubdued } from '../styles/colors';

const Content = styled.section`
  max-width: ${breakpoints.maxWidth}px;
  margin-top: 1rem;
  margin-bottom: 1rem;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  display: flex;
  gap: 2rem;
  position: relative;
  text-align: center;
  max-width: 600px;

  ${fromDesktop} {
    max-width: 800px;
  }
`;

const Details = styled.article`
  font-size: 1.5rem;
  text-align: center;
  position: relative;
  display: flex;
  flex-direction: column;

  ${toTablet} { 
    padding: 0 1em 0.5em;
    position: relative;
  }
`;

const SmallTitle = styled.h3`
  font-size: 1.3em;
  margin-top: 0.5em;
  margin-bottom: 0;
`;

const SlotBooking = styled.p`

`;

const SlotButton = styled.div`
  display: block;
  margin-top: 0.5em;
  font-size: 2rem;

  a {
    display: inline-block;
  }
`;

const AvailableSlots = styled.div`
  background-color: ${primarySubdued};
  font-size: 1.5rem;
  padding: 2.5rem 1rem;
  margin: 0.5rem 0;
  width: 100vw;
  text-align: center;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${fromMedium} {
    margin: 2rem 0 1.5rem;
  }

  ${SmallTitle} {
    font-size: 3rem;
  }

  &::before {
    display: block;
    width: 100%;
    height: 105%;
    content: ' ';
    background-color: ${primary};
    transform: rotate(-1.5deg);
    position: absolute;
    top: -1rem;
    left: 0;
    z-index: -1;
  }
`;

const SlotButtons = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0;
  
  ${fromMedium} {
    flex-direction: row;
    gap: 2em;
  }
`;

export default function Mentoring() {
  return (
    <Page>
        <Content>
            <Details>
              <PageTitle align="center">Mentoring</PageTitle>

              <p>Ask me anything about <strong>learning digital art</strong>, <strong>creating comics</strong>, <strong>self-publishing</strong>, <strong>tabling at events</strong>, or something else you feel I could help you with.</p>

              <p>Make it a one-off or book it regularly: whatever your project needs to come to fruition.</p>
            </Details>

            <AvailableSlots>
              <SmallTitle>Available Slots</SmallTitle>

              <SlotButtons>
                <SlotBooking>
                  Half-hour session: £35

                  <SlotButton>
                    <ButtonLink href="https://calendly.com/claudiamatosa/mentoring-session-with-claudia-half-hour" target="_blank">Book half-hour</ButtonLink>
                  </SlotButton>
                </SlotBooking>
                
                <SlotBooking>
                  One-hour session: £60

                  <SlotButton>
                    <ButtonLink href="https://calendly.com/claudiamatosa/mentoring-session-with-claudia-one-hour" target="_blank">Book one hour</ButtonLink>
                  </SlotButton>
                </SlotBooking>
              </SlotButtons>
            </AvailableSlots>

            <Details>
              <SmallTitle>
                The Small Print
              </SmallTitle>

              <p>The sessions are one-on-one and happen online via video call (Zoom). They are available for people over the age of 18 only.</p>

              <p>In case you have any further questions or are wondering if I'm the right mentor for you, please do reach out using the form below!</p>
            </Details>
        </Content>

        <AboutMe />
    </Page>
  )
}
